/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "link": {
    "colors_default": "",
    "size_medium": "text-body-md !font-normal pt-4 md:pt-0 md:top-[42px] md:right-8",
    "size_large": "text-body-md !font-normal pt-4 md:pt-0 md:top-[50px] md:right-4",
    "variant_default": "hover:underline block text-center md:absolute"
  },
  "slider_slide_caption_variant_default": "uppercase",
  "slider_slide_caption_colors_primary": "text-logga-700",
  "slider_slide_caption_size_medium": "pt-1 text-category-md",
  "slider_slide_caption_size_large": "pt-1 text-category-md",
  "slider_slide_description_colors_primary": "text-black",
  "slider_slide_description_variant_default": "line-clamp-2",
  "slider_slide_description_size_medium": "text-headline-sm pt-1.5 md:pt-2 pb-1",
  "slider_slide_description_size_large": "text-headline-sm pt-2 pb-1",
  "slider_slide_group_variant_default": "!scrollbar-none sm:!snap-none",
  "slider_slide_group_colors_primary": "scrollbar-none",
  "slider_slide_group_size_medium": "!pb-0 gap-3 md:gap-8",
  "slider_slide_group_size_large": "!pb-0 gap-4 md:gap-6",
  "slider_slide_image_variant_default": "after:hidden",
  "slider_slide_image_colors_primary": "",
  "slider_slide_image_size_medium": "w-auto min-w-60 md:min-w-75 [&_picture]:aspect-[1.89/1] [&_img]:aspect-[1.89/1] md:[&_picture]:aspect-[2/1] md:[&_img]:aspect-[2/1]",
  "slider_slide_image_size_large": "w-auto min-w-60 md:min-w-75 [&_picture]:aspect-[1/1.32] [&_img]:aspect-[1/1.32] md:[&_picture]:aspect-[1/1.5] md:[&_img]:aspect-[1/1.5]",
  "slider_slide_variant_default": "flex flex-col relative snap-center",
  "slider_slide_colors_primary": "",
  "slider_slide_size_medium": "gap-2",
  "slider_slide_size_large": "",
  "slider_headline_colors_primary": "",
  "slider_headline_variant_default": "",
  "slider_headline_size_medium": "text-headline-sm",
  "slider_headline_size_large": "text-headline-sm md:text-headline-md",
  "slider_colors_primary": "bg-logga-300",
  "slider_variant_default": "flex-col flex overflow-hidden [&_a]:flex-1",
  "slider_size_medium": "gap-3 !py-0",
  "slider_size_large": "gap-3 !py-0",
  "arrow_variant_right": "absolute -translate-y-full z-10 cursor-pointer top-[49%] right-10",
  "arrow_variant_left": "absolute -translate-y-full z-10 cursor-pointer rotate-180 top-[49%] left-10",
  "arrow_colors_primary": "text-white [&_path]:stroke-black",
  "arrow_size_medium": "hidden md:block",
  "arrow_size_large": "hidden md:block",
  "navigation_item_variant_default": "rounded-full",
  "navigation_item_colors_primary": "bg-secondary-400 data-[active=true]:bg-secondary-600",
  "navigation_item_size_medium": "w-3 h-3",
  "navigation_item_size_large": "",
  "navigation_variant_default": "hidden",
  "navigation_colors_primary": "",
  "navigation_size_medium": "gap-3 pt-6 md:pt-9",
  "navigation_size_large": "",
  "variant_default": "relative rounded md:rounded-none overflow-hidden wings",
  "colors_primary": "bg-logga-300 wings-logga-300",
  "size_medium": "px-4 py-6 md:py-10",
  "size_large": "px-4 py-8 md:py-10"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;