import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const AllasStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          className: 'bg-transparent !p-0',
          $slider: {
            className: 'bg-transparent',
            $headline: {
              className: 'text-headline-xs md:text-headline-sm',
            },
            $caption: {
              className: 'capitalize',
            },
          },
        },
      },
      options: {
        $arrow: {
          className: 'w-2 md:mt-1 ml-2',
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};
